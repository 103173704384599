import { numberComma } from '@Src/_basic/helpers/NumberHelpers';
import { TextLineVariantEnum, UpdateBasicInfoRequest } from '@Src/_basic/object/ProductionHistoryType';
import { useProductionHistory } from '@Src/redux/productionHistory/productionHistoryActions';
import { Box, Paper } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import TextLine from '../../TextLine';
import ActionBar from './ActionBar';

const basicInfoSchema = Yup.object().shape({
  projectAddress: Yup.string().required('請填入工程地址'),
  engName: Yup.string().required('請填入工程名稱'),
  startTime: Yup.number()
    .required('請填入工程開始時間')
    .max(Yup.ref('endTime'), '工程開始時間不得大於工程預計完成時間'),
  endTime: Yup.number()
    .required('請填入工程預計完成時間')
    .min(Yup.ref('startTime'), '工程預計完成時間不得小於工程開始時間'),
});

export default function BasicInfo() {
  const { t: i18T } = useTranslation();
  const [editMode, setEditMode] = React.useState(false);
  const { projectInfo, updateBasicInfo } = useProductionHistory();

  const handleSubmitButtonClick = useCallback(
    (values: UpdateBasicInfoRequest) => {
      setEditMode(false);
      updateBasicInfo(values);
    },
    [updateBasicInfo],
  );

  const handleCancelcelButtonClick = useCallback(() => {
    setEditMode(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setEditMode(true);
  }, []);

  return (
    <Paper elevation={4} sx={{ position: 'relative', padding: '12px 16px' }}>
      <Formik
        initialValues={projectInfo.basicInfo}
        validationSchema={basicInfoSchema}
        onSubmit={handleSubmitButtonClick}
        enableReinitialize
      >
        {(formik) => (
          <Form>
            <Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                <Field
                  name="engName"
                  component={TextLine}
                  title={`${i18T(`REPORT.PRODUCTION_HISTORY.INFORMATION.BASIC_INFO.ENG_NAME`)}：`}
                  content={projectInfo?.basicInfo.engName}
                  editable={editMode}
                  variant={TextLineVariantEnum.TEXT}
                />
                <Field
                  name="projectAddress"
                  component={TextLine}
                  title={`${i18T(`REPORT.PRODUCTION_HISTORY.INFORMATION.BASIC_INFO.ADDRESS`)}：`}
                  content={projectInfo?.basicInfo.projectAddress}
                  editable={editMode}
                  variant={TextLineVariantEnum.TEXT}
                />
                <Field
                  name="startTime"
                  component={TextLine}
                  title={`${i18T(`REPORT.PRODUCTION_HISTORY.INFORMATION.BASIC_INFO.START_DATE`)}：`}
                  content={moment(projectInfo?.basicInfo.startTime).format('YYYY/MM/DD')}
                  editable={editMode}
                  variant={TextLineVariantEnum.DATE}
                  maxValue={formik.values.endTime}
                />
                <Field
                  name="endTime"
                  component={TextLine}
                  title={`${i18T(`REPORT.PRODUCTION_HISTORY.INFORMATION.BASIC_INFO.END_DATE`)}：`}
                  content={moment(projectInfo?.basicInfo.endTime).format('YYYY/MM/DD')}
                  editable={editMode}
                  variant={TextLineVariantEnum.DATE}
                  minValue={formik.values.startTime}
                />
                <TextLine
                  title={`${i18T(`REPORT.PRODUCTION_HISTORY.INFORMATION.BASIC_INFO.SHIPMENT_QUANTITY`)}：`}
                  content={`${numberComma(projectInfo?.basicInfo.deliverAmount || 0)} (M³)`}
                  variant={TextLineVariantEnum.NUMBER}
                />
              </Box>
              <ActionBar
                editMode={editMode}
                onEditButtonClick={handleEditButtonClick}
                onConfirmButtonClick={formik.submitForm}
                onCancelButtonClick={() => {
                  formik.resetForm();
                  handleCancelcelButtonClick();
                }}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Paper>
  );
}
