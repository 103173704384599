import { CreateSignatureItemRequest, SignatureData } from './SignatureType';
import { TestItemEnum } from './TestType';

export interface ProjectData {
  orderId: string;
  engName: string;
  address: string;
  createDate: number;
  expireDate: number;
  sumqty: number;
  profile: boolean;
}

export interface PageableProjectsData {
  data: Array<ProjectData>;
  maxPage: number;
  totalElements: number;
}

export interface SearchProjectsParams {
  id?: string;
  engName: string;
  facCode: string;
  startTime: number;
  endTime: number;
}

export interface SearchPageableProjectsParams extends SearchProjectsParams {
  page: number;
  pageSize: number;
  factoryId?: number;
}

export interface ProjectInfoData {
  projectInfoId: number;
  orderId: string;
  engName: string;
  factoryId: number;
  basicInfo: BasicInfoData;
  signature: SignatureData | null;
  proportion: Array<ProportionData>;
  reports: Array<FileData>;
}

export interface BasicInfoData {
  id: number;
  projectAddress: string;
  startTime: number;
  endTime: number;
  deliverAmount: number;
  engName: string;
}

export interface CreateSignatureRequest {
  projectInfoId: number;
  factoryId: number;
  items: Array<CreateSignatureItemRequest>;
}
export interface UpdateBasicInfoRequest {
  id: number;
  projectAddress: string;
  startTime: number;
  endTime: number;
  engName: string;
}

export interface ProportionData {
  id: number;
  displayName: string;
  designedStrength: number;
  carbonEmissionFactor: number;
  carbonEmissionLifeCycleFactor: number;
  realProps: Array<RealPropData>;
}

export interface RealPropData {
  id: number;
  proportionName: string;
}

export interface CreateProjectInfoProfileByOrderIdRequest {
  factoryId: number;
  orderId: string;
  engName: string;
  projectAddress: string;
}

export interface CreateProportionRequest {
  projectInfoId: number;
  displayName: string;
  designedStrength: number;
  realProps: Array<CreateProportionRealPropsRequest>;
}

export interface CreateProportionRealPropsRequest {
  proportionName: string;
}

export interface CreateProportionsFormType {
  proportions: Array<CreateProportionFormType>;
}

export interface CreateProportionFormType {
  displayName: string;
  designedStrength: number;
  carbonEmissionFactor: number;
  carbonEmissionLifeCycleFactor: number;
  realProps: Array<CreateProportionRealPropsRequest>;
}

export interface UpdateProportionRequest {
  id: number;
  displayName: string;
  designedStrength: number;
  realProps: Array<CreateProportionRealPropsRequest>;
}

export interface ReplaceProportionRequest {
  projectInfoId: number;
  items: Array<ReplaceProportionItemRequest>;
}

export interface ReplaceProportionItemRequest {
  id?: number;
  displayName: string;
  designedStrength: number;
  carbonEmissionFactor: number;
  carbonEmissionLifeCycleFactor: number;
  realProps: Array<CreateProportionRealPropsRequest>;
}

export interface ReplaceProportionsFormType {
  proportions: Array<ReplaceProportionItemRequest>;
}

export interface FileData {
  id: number;
  exptItemCode: string | null;
  name: string;
  uploadFileName: string;
  uploaderId: string;
  type: string;
  path: string;
  download: string;
  createTime: number;
  customUploadTime: number;
  itemId: number | null;
  uploadType: number;
  factoryId: number | null;
  orderId: string | null;
}

export interface CreateFileParams {
  uploaderId: string;
  factoryId: number;
  orderId: string;
}

export interface SearchConcreteHeaderParams {
  orderId: string;
  factoryId: number;
  startTime: number;
  endTime: number;
  timezone: string;
  realPropNames: Array<string>;
}

export interface ConcreteHeaderData {
  days: number;
  sumQty: number;
  ttlTruck: number;
  exptTtlTruck: number;
  slagAbnormalDays: number;
  chlorideIonAbnormalDays: number;
  returnTruck: number;
}

export interface SearchConcreteExptsParams {
  id?: string;
  factoryId: number;
  orderId: string;
  startTime: number;
  endTime: number;
  timezone: string;
  realPropNames: Array<string>;
}

export interface SearchPageableConcreteExptsParams extends SearchConcreteExptsParams {
  page: number;
  pageSize: number;
}

export interface ConcreteExptData {
  shipmentHeaderId: number;
  plateNumber: string;
  falsePropName: string;
  designStr: number;
  workPart: string;
  exptId: number;
  samplingTtime: number;
  actualSlump: number;
  materialStatusId: number;
  materialStatusName: string;
  previewType: string;
  previewUrl: string;
  avgCompStr: number;
  achivedDesignStr: number;
  displayName: string;
  exptType: TestItemEnum;
}

export interface PageableConcreteExptData {
  maxPage: number;
  totalElements: number;
  data: Array<ConcreteExptData>;
}

export interface SearchConcreteFilesParams {
  projectInfoId: number;
  factoryId: number;
  startTime: number;
  endTime: number;
  timezone: string;
}

export interface GroupedConcreteExptData {
  concreteExpts: Array<ConcreteExptData>;
  reports: Array<FileData>;
}

export enum TextLineVariantEnum {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  OPTION = 'option',
}

export enum RibbonVariantEnum {
  CEMENTITIOUS = 'cementitious',
  CHEMICAL = 'chemical',
  SAND_AND_ROCK = 'sandandrock',
}

export enum SignatureStepEnum {
  CREATE_BASIC = 'createBasic',
  CREATE_ITEMS = 'createItems',
  REUSED_BASIC = 'reusedBasic',
  REUSED_ITEMS = 'reusedItems',
  SELECT_STEP = 'selectStep',
}

export enum SignatureTypeEnum {
  CEMENTITIOUS = 'cementingMaterial',
  CHEMICAL = 'chemicalAdmixture',
  SAND_AND_ROCK = 'sandStone',
}

export enum ProportionStepEnum {
  CREATE_ITEM = 'createItem',
  EDIT_ITEM = 'editItem',
  SHOW_ITEMS = 'showItems',
  SELECT_STEP = 'selectStep',
}
