import { ProjectData, ProjectInfoData } from '@Src/_basic/object/ProductionHistoryType';

export const projects: Array<ProjectData> = [
  {
    orderId: 'S123456789',
    engName: '大樓',
    address: '路',
    createDate: new Date('2023-08-11').getTime(),
    expireDate: new Date('2023-08-11').getTime(),
    sumqty: 123456789,
    profile: false,
  },
];

export const projectInfos: Array<ProjectInfoData> = [
  {
    projectInfoId: 2,
    orderId: 'S123456789',
    engName: '大樓',
    factoryId: 3,
    basicInfo: {
      id: 2,
      engName: '大樓',
      projectAddress: '新北市',
      startTime: 1683276400000,
      endTime: 1683276400000,
      deliverAmount: 415,
    },
    signature: null,
    proportion: [],
    reports: [],
  },
];
