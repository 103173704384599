import { Protocol } from '../Protocol';

export class FileProtocol {
  public static readonly File: string = `${Protocol.YATUNG_API_ROOT}/file`;
  public static readonly Upload_File: string = `${Protocol.YATUNG_API_ROOT}/file/upload`;
  public static readonly FineAndRejectFile: string = `${Protocol.YATUNG_API_ROOT}/file/fineAndRejectFile`;
  public static readonly UploadFineAndRejectFile: string = `${Protocol.YATUNG_API_ROOT}/file/upload/fineAndRejectFile`;
  public static readonly UploadPdfFile: string = `${Protocol.YATUNG_API_ROOT}/pdf/upload`;
  public static readonly CheckPdfFile: string = `${Protocol.YATUNG_API_ROOT}/pdf`;
  public static readonly DownloadPdfFile: string = `${Protocol.YATUNG_API_ROOT}/pdf/download`;
}
