import { setActionLoading } from '@Src/redux/api/apiSlice';
import store from '@Src/redux/store';
import axios, { AxiosRequestConfig } from 'axios';

export class Api {
  private static requestCount = 0;
  private static responseCount = 0;

  public static get(
    url: string,
    config?: AxiosRequestConfig,
    onSuccess?: (responseData: any) => void,
    onFailed?: (res: any) => void,
    onFinally?: () => void,
  ) {
    this.onCall();
    axios
      .get<any>(url, config)
      .then((responseData: any) => {
        onSuccess && onSuccess(responseData);
      })
      .catch((error) => {
        onFailed && onFailed(error.response?.data);
      })
      .finally(() => {
        this.onFinally();
        onFinally && onFinally();
      });
  }

  public static post(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    onSuccess?: (responseData: any) => void,
    onFailed?: (res: any) => void,
    onFinally?: () => void,
  ) {
    this.onCall();
    axios
      .post<any>(url, data, config)
      .then((responseData: any) => {
        onSuccess && onSuccess(responseData);
      })
      .catch((error) => {
        onFailed && onFailed(error.response?.data);
      })
      .finally(() => {
        this.onFinally();
        onFinally && onFinally();
      });
  }

  public static put(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    onSuccess?: (responseData: any) => void,
    onFailed?: (res: any) => void,
    onFinally?: () => void,
  ) {
    this.onCall();
    axios
      .put<any>(url, data, config)
      .then((responseData: any) => {
        onSuccess && onSuccess(responseData);
      })
      .catch((error) => {
        onFailed && onFailed(error.response?.data);
      })
      .finally(() => {
        this.onFinally();
        onFinally && onFinally();
      });
  }

  public static delete(
    url: string,
    config?: AxiosRequestConfig,
    onSuccess?: (responseData: any) => void,
    onFailed?: (res: any) => void,
    onFinally?: () => void,
  ) {
    this.onCall();
    axios
      .delete<any>(url, config)
      .then((responseData: any) => {
        onSuccess && onSuccess(responseData);
      })
      .catch((error) => {
        onFailed && onFailed(error.response?.data);
      })
      .finally(() => {
        this.onFinally();
        onFinally && onFinally();
      });
  }

  public static patch(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    onSuccess?: (responseData: any) => void,
    onFailed?: (res: any) => void,
    onFinally?: () => void,
  ) {
    this.onCall();

    axios
      .patch<any>(url, data, config)
      .then((responseData: any) => {
        onSuccess && onSuccess(responseData);
      })
      .catch((error) => {
        onFailed && onFailed(error.response?.data);
      })
      .finally(() => {
        this.onFinally();
        onFinally && onFinally();
      });
  }

  public static download(
    url: string,
    config?: AxiosRequestConfig,
    onSuccess?: (responseData: any) => void,
    onFailed?: (res: any) => void,
    onFinally?: () => void,
  ) {
    this.onCall();
    const originalAxios = axios.create();
    originalAxios
      .get(url, {
        ...config,
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const fileName = decodeURI(response.headers['content-disposition'].split(' ')[1].replace('filename=', ''));
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.removeChild(link);
        onSuccess && onSuccess(response.data);
      })
      .catch((error) => {
        onFailed && onFailed(error);
      })
      .finally(() => {
        this.onFinally();
        onFinally && onFinally();
      });
  }

  private static onCall() {
    this.requestCount++;
    store.dispatch(setActionLoading(true));
  }

  private static onFinally() {
    this.responseCount++;
    if (this.isLoaded()) {
      this.requestCount = 0;
      this.responseCount = 0;
      store.dispatch(setActionLoading(false));
    }
  }

  private static isLoaded() {
    return this.requestCount === this.responseCount;
  }
}
